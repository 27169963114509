<template>
  <div id="app">
    <mds-application-header>
      <template #mds-application-header-custom-logo>
        <mds-button-container>
          <mds-button
            v-if="!isRegisterPage"
            id="home-trigger"
            type="button"
            variation="icon-only"
            icon="home"
            href="/home"
            @mouseover="homeTipVisible = true"
            @mouseleave="homeTipVisible = false"
            @focus="homeTipVisible = true"
            @blur="homeTipVisible = false"
            >Ratings Engine Home</mds-button
          >
          <mds-button variation="flat" type="button" size="large">
            DBRS Access Review Application
          </mds-button>
        </mds-button-container>
      </template>
      <template #mds-application-header-search>
        <img
          :src="logoPath"
          size
          alt="Morningstar | DBRS"
          width="195"
          height="42"
          class="mds-page-shell__masthead-logo"
        />
      </template>
      <template #mds-application-header-actions-right>
        <div class="thead-right-options">
          <div>
            <mds-button
            v-if="!isRegisterPage"
              variation="flat"
              icon="person"
              flat-button-icon-size="medium"
              @click="logout()"
              >Sign Out</mds-button
            >
          </div>
        </div>
      </template>
    </mds-application-header>
    <div class="ciAudit-loader" v-if="showLoader">
      <div class="ciAudit-loader__overlay"/>
      <mds-loader size="small" aria-label="Small Loader" ></mds-loader>
    </div>
      <mds-alert variation="success" tinted v-if="successAlertVisible">
          {{ successAlertMessage }}
      </mds-alert>
      <mds-alert variation="error" tinted v-if="errAlertVisible" @mds-alert-dismissed="errAlertVisible = false">
      {{ errAlertMessage }}</mds-alert>

    <router-view
    :showLoaderToggle="showLoaderToggle"
    :showSuccessAlert="showSuccessAlert"
    :showErrorAlert="showErrorAlert">
  </router-view>
  </div>
</template>
<script>
import logo from "@/assets/logo.svg";
import { MdsButton, MdsButtonContainer } from "@mds/button-vue3";
import MdsApplicationHeader from "@mds/application-header-vue3";
import { logoutUim } from './userAuthentication';
import MdsLoader from '@mds/loader-vue3';
import MdsAlert from '@mds/alert-vue3';
export default {
  name: "App",
  components: {
    MdsButton,
    MdsButtonContainer,
    MdsApplicationHeader,
    MdsLoader,
    MdsAlert
  },
  computed: {
    isRegisterPage() {
      return this.$route?.matched?.some(({ name }) => name === 'register' || name === 'landingpage');
    }
  },
  data() {
    return {
      logoPath: logo,
      showLoader: false,
      successAlertVisible: false,
      errAlertMessage: "",
      errAlertVisible: false
    };
  },
  watch: {
    $route: function () {
      let activeTabObj = {
        name: "landingpage",
        routerLink: "/landing-page",
      };
      if (this.$route.path === "/") {
        this.activeTab = activeTabObj;
        this.$router.push(this.activeTab.routerLink);
      }
    },
  },
  methods: {
    logout() {
      logoutUim();
    },
    showLoaderToggle(flag) {
      this.showLoader = flag;
    },
    showSuccessAlert(alertMessage) {
      this.successAlertVisible = true;
      this.successAlertMessage = alertMessage;
      let _this = this;
      setTimeout(() => {
        _this.successAlertVisible = false;
      }, 5000);
    },
    showErrorAlert(alertMessage) {
      this.errAlertVisible = true;
      this.errAlertMessage = alertMessage;
      let _this = this;
      setTimeout(() => {
        _this.errAlertVisible = false;
      }, 5000);
    },
    navigateTo(path){
      this.$router.push(path)
    }
  },
};
</script>

<style lang="scss">
.mds-application-header {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  border-bottom: 1px solid #e5e5e5;
}

.thead-right-options {
  display: flex;
  align-items: flex-start;
}
.ciAudit-loader{
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  &__overlay{
    background: #fff;
    opacity: 0.6;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
.mds-alert{
  width: 30%;
  left: 36%;
  position: absolute;
  z-index: 100;
}

</style>
