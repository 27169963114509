<template>
  <div class="home">
    <Dashboard v-if="isAdmin"
      @manager-Lead-List="getManagerLeadList"
      >
    </Dashboard>
    <div v-if="showData">
      <screen-designer ref="screenDesigner" :ui-schema="uiSchema" :data="uiData">
      </screen-designer>
      <p class="bottom-text">If you would like to understand which CI Role is allowed access to which application,
        <a href="https://mswiki.morningstar.com/display/CRT/DBRS+Access+Review+Application+-+How+to+use#DBRSAccessReviewApplicationHowtouse-CIProfiletoApplicationsMapping:" target="_blank">please click here</a> 
        to view and download the excel mapping file.</p>
      <p class="bottom-text">If you don’t have access to above wiki page, please raise Sailpoint request for “Confluence-CRT-Users”. </p>
    </div>
  </div>
</template>

<script>
import ScreenDesigner from "@cr/screen-builder/src/components/screen-builder/ScreenDesigner.vue";
import Dashboard from '../components/Dashboard.vue'
import EventBus from "@cr/screen-builder/src/eventbus";
// import importUsers from "@/configData/importUsers.json";
import debounce from "lodash/debounce";
// import sortBy from "lodash/sortBy";
import { updateEmployees, getEmployees } from '../apis/ci-audit.js';
const DEFAULT_DEBOUNCE_DELAY = 200; //milliseconds

export default {
  name: "Home",
  components: {
    ScreenDesigner,
    Dashboard
  },
  data() {
    return {
      uiSchema: {
        type: "VerticalLayout",
        elements: [
          {
            type: "HorizontalLayout",
            elements: [
              {
                type: "Section",
                customOptions: {
                  border: "primary",
                  title: "List of Mismatched Accesses",
                  container: true,
                  bold: true,
                  collapsible: true,
                  expanded: true,
                },
                elements: [
                  {
                    type: "HorizontalLayout",
                    style: {
                      marginBottom: "16px",
                    },
                    elements: [
                      {
                        type: "select",
                        key: "leadManager",
                        customOptions: {
                          label: "Logged In As:",
                          disabled: true,
                        },
                        selectOptions: [
                          {
                            text: "Manager",
                            value: 1
                          },
                          {
                            text: "Tech Lead",
                            value: 2
                          },
                        ],
                        events: [
                          {
                            type: "change",
                            name: "on-my-select-change",
                          },
                        ],
                        colspan: 2,
                      },
                      {
                        type: "select",
                        key: "leadManagerList",
                        customOptions: {
                          label: "List of Managers and Tech Leads:",
                          hidden: false,
                          placeholder:'Select an Option'
                        },
                        selectOptions:[],
                        events: [
                          {
                            type: "change",
                            name: "on-my-manager-select-from-admin-change",
                          },
                        ],
                        colspan: 3,
                      },
                      {
                        type: "input",
                        dataType: "text",
                        key: "searchQuery",
                        customOptions: {
                          label: "Search by User Email",
                        },
                        events: [
                          {
                            type: "input",
                            name: "onSearchQueryInputDebounced"
                          }
                        ],
                        colspan: 3,
                      },
                      {
                        type: "button",
                        customOptions: {
                          variation: "primary",
                          icon: "open-new",
                          text: "Update Users",
                          disabled: true
                        },
                        classes: 'update-btn',
                        events: [
                          {
                            type: "click",
                            extra: "This is extra data",
                            name: "on-user-update",
                          },
                        ],
                        colspan: 4,
                      },
                    ],
                  },
                  {
                    type: "HorizontalLayout",
                    style: {
                      marginBottom: "16px",
                      height: "330px",
                    },
                    elements: [
                      {
                        type: "Grid",
                        ref:'accessManagementGrid',
                        gridRef:'accessManagement',
                        theme: "column-theme",
                        key: "filteredData",
                        multiselection: false,
                        fixedLayout: true,
                        maxWidth: 6000,
                        verticalExpandable: true,
                        requireRowAddRemove: false,
                        requireMinRowsCount: 0,
                        emptyTableMessage:"",
                        events: [
                          {
                            type: "data-change",
                            extra: "This is extra data",
                            name: "on-rl-recoveries-change",
                          },
                          {
                            type: "row-selection-change",
                            extra: "This is extra data",
                            name: "on-row-selection-change",
                          },
                          {
                            type: "cell-value-change",
                            name: "on-column-manager-change"
                          },
                          {
                            type: "blur",
                            name: "on-comment-enter"
                          },
                          {
                            type: "paste",
                            name: "on-data-paste"
                          }
                        ],
                        colConfigs: [
                          {
                            key: "name",
                            label: "Application Name",
                            dataType: "text",
                            readonly: true,
                            sortable: false,
                            sorted: 1,
                            width: "200px",
                          },
                          {
                            key: "description",
                            label: "Application Description",
                            dataType: "text",
                            readonly: true,
                            width: "200px",
                            requireTooltip: true,
                            truncateText: true,
                          },
                          {
                            key: "uEmail",
                            label: "User Email Address",
                            dataType: "text",
                            readonly: true,
                            sortable: false,
                            sorted: 1,
                            width: "250px",
                          },
                          {
                            key: "accessType",
                            label: "Type of Access",
                            dataType: "text",
                            readonly: true,
                            sortable: false,
                            sorted: 1,
                            width: "100px",
                          },
                          
                          {
                            key: "ciRoles",
                            label: "CI Mapping Role",
                            dataType: "text",
                            readonly: true,
                            sortable: false,
                            sorted: 1,
                            width: "150px",
                            requireTooltip: true,
                            truncateText: true,
                            hideNoDataSymbol: true
                          },
                        ],
                        rowConfigs: []
                      },
                    ],
                  },
                  {
                    type: "HorizontalLayout",
                    style: {
                      marginBottom: "16px",
                    },
                    elements: [
                      {
                        type: "pagination",
                        customOptions: {
                          totalItems: 0,
                          showItemsInfo: true,
                          showItemsSelect: false,
                          page: 1,
                          pageSize: 10,
                          pageSizes: [10, -1],
                        },
                        events: [
                          {
                            type: "page-change",
                            name: "on-pagination-page-change",
                          },
                        ],
                        classes: 'pagination-grid',
                        colspan: 12,
                      },
                    ],
                  },
                ],
                colspan: 12,
              },
            ],
          },
        ],
      },
      uiData: {
        rl_recoveries: {},
        filteredData: [],
        searchQuery: "",
        managerRecommendation: [],
        currentAccess: [],
        leadManager: 1,
        managerList:[],
        techLeadList:[],
        leadManagerList:"",
        description:""
      },
      showData: false,
      gridConfig: {},
      isManager: false,
      payloadForUpdateAPI: {
        employees: []
      },
      recordsAfterPagination: [],
      colsConfigManager: [{
        key: "managerRecommendation",
        label: "Manager Recommendation",
        dataType: "select",
        options: [
          { value: "", text: "Please Select an option" },
          { value: "Approved", text: "Approved" },
          { value: "Unapproved", text: "Unapproved" },
        ],
        events: [
          {
            type: "cell-value-change",
            name: "on-column-manager-change"
          }
        ],
        width: "180px",
        headerIcon: "info-circle",
        headerIconTooltipText: "Approved means user is allowed to access the application. Once manager approves the access, this access will be approved and no longer shown on the screen.Unapproved means user is not allowed to access the application. Once manager unapproves the access, respective team will be informed to remove the user access to that application.",
      },
      {
        key: "comment",
        label: "Comment",
        dataType: "text",
        readonly: false,
        width: "150px",
        hide:false,
        truncateText: true,
        hideNoDataSymbol: true,
        requireTooltip: true,
        skipCopiedTextParse:true,
        customBindingsFn: this.customBindingFunction
      },
      {
        key: "currentAccess",
        label: "Current Access Status",
        dataType: "text",
        readonly: true,
        width: "150px",
        headerIcon: "info-circle",
        headerIconTooltipText: "Provides the current status of the user access based on recommendation given by the Manager.",
      }
      ],
      colsConfigLead: [
        {
          key: "managerRecommendation",
          label: "Manager Recommendation",
          dataType: "text",
          readonly: true,
          width: "180px",
          headerIcon: "info-circle",
          headerIconTooltipText: "Approved means user is allowed to access the application. Once manager approves the access, this access will be approved and no longer shown on the screen.Unapproved means user is not allowed to access the application. Once manager unapproves the access, respective team will be informed to remove the user access to that application.",
        },
        {
          key: "currentAccess",
          label: "Current Access",
          dataType: "select",
          options: [
            { value: "", text: "Please Select an option" },
            { value: "Unapproved", text: "Unapproved" },
          ],
          events: [
            {
              type: "cell-value-change",
              name: "on-column-manager-change"
            }
          ],
          width: "180px",
          headerIcon: "info-circle",
          headerIconTooltipText: "Provides the current status of the user access based on recommendation given by the Manager.",
        },
      ],
      paginatePageNumber: 1,
      techLeadUpdate: false,
      isAdmin: false,
      emailSelected:'',
      listOfOptions:[],
      gridRow:{}
    };
  },
  props: ['showLoaderToggle', 'showSuccessAlert','showErrorAlert'],
  watch: {
    'uiData.searchQuery': function (newVal) {
      // handler(newVal) {
      let gridRef = this.uiSchema.elements[0].elements[0].elements[1].elements[0]
      let gridConfigForPagination = this.uiSchema.elements[0].elements[0].elements[2].elements[0];
      let filteredOnName = '';
      if (this.isManager) {
        filteredOnName = this.uiData.rl_recoveries.manager_data.filter(r =>
          r.uEmail.toLowerCase().includes(newVal)
        );
      }
      else {
        filteredOnName = this.uiData.rl_recoveries.tech_lead_data.filter(r =>
          r.uEmail.toLowerCase().includes(newVal)
        );
      }
      this.recordsAfterPagination = filteredOnName
      this.uiData.filteredData = filteredOnName
      this.gridConfig.totalItems = this.uiData.filteredData.length;
      this.uiData.filteredData = this.uiData.filteredData?.filter((_deal, index) => {
        return this.filterByPagination(index);
      });
      if (this.uiData.filteredData.length === 0) {
        gridConfigForPagination.hidden = true
      } else {
        gridConfigForPagination.hidden = false
      }
      this.rowsDisabled(gridRef, this.uiData.filteredData)
      }
    // }
  },
  mounted() {
    this.isAdmin = localStorage.getItem('isAdmin')
    if(this.isAdmin == 'false'){
      this.isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
      this.getEmployeesList()
      let listOfManager = this.uiSchema.elements[0].elements[0].elements[0].elements[1]
      listOfManager.customOptions.hidden = true
    }else{
      let searchFied = this.uiSchema.elements[0].elements[0].elements[0].elements[2]
      searchFied.customOptions.hidden = true
    }
    EventBus.$on("on-row-selection-change", this.onRowSelectionChange);
    EventBus.$on("on-pagination-page-change", this.onPaginationPageChange);
    EventBus.$on("on-my-select-change", this.onChangeManager);
    EventBus.$on("on-column-manager-change", this.onManagerRecommendationChange);
    EventBus.$on("onSearchQueryInputDebounced", this.onSearchQueryInputDebounced);
    EventBus.$on("on-user-update", this.userUpdate)
    EventBus.$on("on-my-manager-select-from-admin-change", this.onSelectLeadManagerFromAdmin);
    EventBus.$on('on-comment-enter',this.onEnterComment)
    EventBus.$on('on-data-paste',this.onDataPaste)
  },
  methods: {
    customBindingFunction(rowIndex) {
      const managerRecomm = this.uiData.filteredData[rowIndex].managerRecommendation;
      if (managerRecomm === 'Unapproved') {
        return {
          readonly: true
        }
      }
    },
    onPaginationPageChange(data) {
      this.paginatePageNumber = data
      let gridRef = this.uiSchema.elements[0].elements[0].elements[1].elements[0]
      const startIndex = (data - 1) * this.gridConfig.pageSize;
      const endIndex = startIndex + this.gridConfig.pageSize;
      if (this.recordsAfterPagination.length > 0) {
        this.filteredData = this.recordsAfterPagination.slice(startIndex, endIndex);
      } else {
        if (this.uiData.rl_recoveries.manager_data.length > 0 && this.isManager) {
          this.filteredData = this.uiData.rl_recoveries.manager_data.slice(startIndex, endIndex);

        } else {
          this.filteredData = this.uiData.rl_recoveries.tech_lead_data.slice(startIndex, endIndex);
        }
      }
      this.uiData.filteredData = this.filteredData
      //Making those Rows Disabled which have manager recommendation cols as Unapproved 
      this.rowsDisabled(gridRef, this.uiData.filteredData)
    },
    filterByPagination(index) {
      return index >= this.gridConfig.firstItem - 1 && index < this.gridConfig.lastItem;
    },
    setPaginationOptions() {
      const defaultPageSize = this.gridConfig.pageSizes[0];
      this.gridConfig.page = 1;
      this.gridConfig.firstItem = 1;
      this.gridConfig.lastItem = defaultPageSize;
    },
    onChangeManager(payload) {
      this.paginatePageNumber = 1
      this.payloadForUpdateAPI.employees = [];
      this.uiData.searchQuery = '';
      this.recordsAfterPagination = [];
      let updateUserButton = this.uiSchema.elements[0].elements[0].elements[0].elements[3]
      updateUserButton.customOptions.disabled = true
      // let listOfManager = this.uiSchema.elements[0].elements[0].elements[0].elements[1]
      // listOfManager.customOption.selected=''
       if(this.isAdmin && this.uiData.filteredData.length === 0){
        this.getManagerAndLeadList(payload)
        if(this.emailSelected){
          this.onSelectLeadManagerFromAdmin(this.emailSelected)
        }
      }else{
        let gridRef = this.uiSchema.elements[0].elements[0].elements[1].elements[0]
        let colConfigLength = gridRef.colConfigs.length
        let listOfManager = this.uiSchema.elements[0].elements[0].elements[0].elements[1]
        if (payload !== '' && payload == 1) {
            this.uiData.filteredData = this.uiData.rl_recoveries.manager_data
            this.gridConfig.totalItems = this.uiData.filteredData.length;
            this.isManager = true
            this.uiData.leadManager = 1
            if(gridRef.colConfigs.length === 7){
              gridRef.colConfigs.splice(colConfigLength - 2, 2)
              let newArr = gridRef.colConfigs.concat(this.colsConfigManager)
              gridRef.colConfigs = newArr
            }
            this.uiData.filteredData = this.uiData.filteredData?.filter((_deal, index) => {
              return this.filterByPagination(index);
            });
            listOfManager.selectOptions = this.uiData.managerList.length>0?this.uiData.managerList:[]
            // this.rowsDisabled(gridRef, this.uiData.filteredData)
          } else {
            this.uiData.filteredData = this.uiData.rl_recoveries.tech_lead_data
            this.gridConfig.totalItems = this.uiData.filteredData.length;
            this.isManager = false
            this.uiData.leadManager = 2
            if(gridRef.colConfigs.length === 8){
              gridRef.colConfigs.splice(gridRef.colConfigs.length-1,1)
            }
            let colConfigLength = gridRef.colConfigs.length
        
            gridRef.colConfigs.splice(colConfigLength - 2, 2)
            let newArr = gridRef.colConfigs.concat(this.colsConfigLead)
            gridRef.colConfigs = newArr
            this.uiData.filteredData = this.uiData.filteredData?.filter((_deal, index) => {
              return this.filterByPagination(index);
            });
            listOfManager.selectOptions = this.uiData.techLeadList.length>0?this.uiData.techLeadList:''
            // this.rowsDisabled(gridRef, this.uiData.filteredData)
          }
          this.rowsDisabled(gridRef, this.uiData.filteredData)
        // }
      }
    },
    onRowSelectionChange() {
      console.log("payload", this.uiData.rl_recoveries)
    },
    onManagerRecommendationChange(payload) {
      let rowValues = payload.rowRef
      let rowObj = {};
      rowObj['name'] = rowValues.name;
      rowObj['uEmail'] = rowValues.uEmail;
      rowObj['accessType'] = rowValues.accessType
      if (this.isManager) {
        rowObj['managerRecommendation'] = rowValues.managerRecommendation;
      } else {
        rowObj['currentAccess'] = rowValues.currentAccess;
      }
      rowObj['isManager'] = this.isManager;
      let gridRef = this.uiSchema.elements[0].elements[0].elements[1].elements[0]
      let rowIndex = payload.rowRef.index;
      let rowConfigs = gridRef.rowConfigs;
      if(rowObj['managerRecommendation']==='Approved'){
        rowConfigs[rowIndex] = rowConfigs[rowIndex] || {};
        rowConfigs[rowIndex].cellConfig = [];
        rowConfigs[rowIndex].cellConfig[gridRef.colConfigs.length - 2] = {};
        rowConfigs[rowIndex].cellConfig[gridRef.colConfigs.length - 2].readonly = false;
        gridRef.colConfigs[gridRef.colConfigs.length - 2].truncateText = false;
        rowObj['comment'] = rowValues.comment?rowValues.comment:''
      }else{
        rowValues.comment = ''
        rowConfigs[rowIndex] = rowConfigs[rowIndex] || {};
        // gridRef.colConfigs[gridRef.colConfigs.length-2].readonly= true
        rowConfigs[rowIndex].cellConfig = [];
        rowConfigs[rowIndex].cellConfig[gridRef.colConfigs.length - 2] = {};
        rowConfigs[rowIndex].cellConfig[gridRef.colConfigs.length - 2].readonly = true;
      }
      let updateUserButton = this.uiSchema.elements[0].elements[0].elements[0].elements[3]
      let foundIndex = this.payloadForUpdateAPI.employees.findIndex(x => x.uEmail == rowObj['uEmail']
      && x.name == rowObj['name'] && x.accessType == rowObj['accessType']);
      
      if(this.isManager)
      {
        if(rowObj['managerRecommendation']==='Unapproved' || rowObj['managerRecommendation']==='Approved')
         {
          if (foundIndex > -1) {
            this.payloadForUpdateAPI.employees[foundIndex] = rowObj
          } else {
              this.payloadForUpdateAPI.employees.push(rowObj)
          }
        }else{
          this.payloadForUpdateAPI.employees.splice(foundIndex,1)
        }
      }
      else{
        if (foundIndex > -1) {
            this.payloadForUpdateAPI.employees.splice(foundIndex,1)
          }else{
            if(rowObj['currentAccess']==='Unapproved'){
              this.payloadForUpdateAPI.employees.push(rowObj)
            }
          }
        }
          let records = this.payloadForUpdateAPI.employees.filter((e)=>{
            return e.managerRecommendation === 'Approved'
          })
          if(records.length === 0){
            rowConfigs[rowIndex] = rowConfigs[rowIndex] || {};
            rowConfigs[rowIndex].cellConfig = [];
            gridRef.colConfigs[gridRef.colConfigs.length-1].hide = false
            // if(gridRef.colConfigs.length === 8){
            //   gridRef.colConfigs.splice(gridRef.colConfigs.length-1,1)
            // }
          }
        // }
      this.payloadForUpdateAPI.employees.length>0 ? updateUserButton.customOptions.disabled=false :
      updateUserButton.customOptions.disabled=true
    },
    onSearchQueryInputDebounced: debounce(
      function (value) {
        this.uiData.searchQuery = value;
      },
      DEFAULT_DEBOUNCE_DELAY,
      {},
      true
    ),
    userUpdate() {
      // if (this.uiData.leadManager == 2) {
      //   this.techLeadUpdate = true
      // } else {
      //   this.techLeadUpdate = false
      // }
      let appRecords = this.payloadForUpdateAPI.employees.filter((rec)=>{
        return rec.managerRecommendation === 'Approved' && rec.comment ===''
      })
      if(appRecords.length>0){
        return this.showErrorAlert('Please provide comment for Approved Records')
      }
      if (this.payloadForUpdateAPI.employees.length > 0) {
        this.showLoaderToggle(true)
        updateEmployees(this.payloadForUpdateAPI)
          .then((resp) => {
            this.showSuccessAlert(resp.data.message)
            this.payloadForUpdateAPI.employees.length = 0
            let updateUserButton = this.uiSchema.elements[0].elements[0].elements[0].elements[3]
            updateUserButton.customOptions.disabled = true
            if(this.emailSelected){
              this.getEmployeesList(this.emailSelected)
            }else{
              this.getEmployeesList()
            }
          })
          .catch((err) => {
            console.log(err)
            this.handleError(err)
          })
          .finally(() => {
            this.showLoaderToggle(false)
          });
      }
    },
    getEmployeesList(email) {
      this.showLoaderToggle(true)
      return getEmployees(email)
        .then((res) => {
          let gridConfigForPagination = this.uiSchema.elements[0].elements[0].elements[2].elements[0];
          let gridRef = this.uiSchema.elements[0].elements[0].elements[1].elements[0]
          let loggedInManager = this.uiSchema.elements[0].elements[0].elements[0].elements[0]
          this.gridConfig = gridConfigForPagination.customOptions
          this.setPaginationOptions();
          let startIndex = Number;
          let endIndex = Number;

          if (this.recordsAfterPagination.length > 0) {
            this.showSearchResultsAfterUpdate(res.data, gridRef)
          } else {
            this.uiData.rl_recoveries = res.data
            let userData = this.uiData.rl_recoveries
            // this.gridConfig.totalItems = res.data.manager_data.length>0?res.data.manager_data.length:res.data.tech_lead_data.length;
            if (this.showData && !this.isAdmin) {
              startIndex = (this.paginatePageNumber - 1) * this.gridConfig.pageSize
              endIndex = startIndex + this.gridConfig.pageSize;
              // this.uiData.filteredData = userData.manager_data.slice(startIndex, endIndex;
            }else {
              startIndex = this.gridConfig.firstItem - 1;
              endIndex = startIndex + this.gridConfig.pageSizes[0];
            }
            
            // check if user is both manager and tech lead
            if (userData.manager_data.length > 0 && userData.tech_lead_data.length > 0 && this.uiData.leadManager == 1) {
              this.isManager = true
              this.uiData.filteredData = userData.manager_data.slice(startIndex, endIndex);
              this.showData = true
              loggedInManager.customOptions.disabled = false
              this.gridConfig.totalItems = userData.manager_data.length;
              if (gridRef.colConfigs.length < 7) {
                gridRef.colConfigs = gridRef.colConfigs.concat(this.colsConfigManager)
              }
            }
            // check if user is manager and tech update is false
            else if (userData.manager_data.length > 0 && this.uiData.leadManager == 1) {
              this.filteredData = this.uiData.rl_recoveries.manager_data
              this.isManager = true
              this.uiData.filteredData = userData.manager_data.slice(startIndex, endIndex);
              this.showData = true
              this.gridConfig.totalItems = userData.manager_data.length;
              if (gridRef.colConfigs.length < 7) {
                gridRef.colConfigs = gridRef.colConfigs.concat(this.colsConfigManager)
              }
            } else if(userData.tech_lead_data.length > 0 && this.uiData.leadManager !== 1) {
              this.filteredData = this.uiData.rl_recoveries.tech_lead_data
              this.isManager = false
              this.uiData.filteredData = userData.tech_lead_data.slice(startIndex, endIndex);
              this.showData = true
              this.uiData.leadManager = 2
              this.gridConfig.totalItems = userData.tech_lead_data.length;
              if (gridRef.colConfigs.length < 6) {
                gridRef.colConfigs = gridRef.colConfigs.concat(this.colsConfigLead)
              }
            }
            else if(userData.tech_lead_data.length > 0 && !this.isAdmin) {
              this.filteredData = this.uiData.rl_recoveries.tech_lead_data
              this.isManager = false
              this.uiData.filteredData = userData.tech_lead_data.slice(startIndex, endIndex);
              this.showData = true
              this.uiData.leadManager = 2
              this.gridConfig.totalItems = userData.tech_lead_data.length;
              if (gridRef.colConfigs.length < 6) {
                gridRef.colConfigs = gridRef.colConfigs.concat(this.colsConfigLead)
              }
            }
            else{
              this.uiData.filteredData.length = 0
              if(this.uiData.leadManager === 1){
                this.isManager = true
              }else{
                this.isManager = false
              }
              // this.uiData.filteredData = userData.tech_lead_data.slice(startIndex, endIndex);
              this.showSuccessAlert("No Data Available.")
              gridRef.emptyTableMessage = "No Data Available"
            }
            if (this.uiData.filteredData.length === 0) {
              gridConfigForPagination.hidden = true
              let searchFied = this.uiSchema.elements[0].elements[0].elements[0].elements[2]
              searchFied.customOptions.hidden = true
            } else {
              gridConfigForPagination.hidden = false
              let searchFied = this.uiSchema.elements[0].elements[0].elements[0].elements[2]
              searchFied.customOptions.hidden = false
            }
            this.rowsDisabled(gridRef, this.uiData.filteredData)
          }
        })
        .catch((err) => {
          console.log(err)
          this.handleError(err)
        })
        .finally(() => {
          this.showLoaderToggle(false)
        })
    },
    rowsDisabled(rows, array) {
      let arr = new Array(10).fill(0).map(() => ({}));
      if (this.isManager) {
        let filterArray = array.filter((data, index) => {
          data['index'] = index
          return data.managerRecommendation === 'Unapproved' && data.currentAccess === 'Unapproval in process'
        })
        rows.rowConfigs = arr;
        let gridRefCopy = rows.rowConfigs
        filterArray.forEach((arr) => {
          let index = arr.index
          gridRefCopy.forEach((data, ind) => {
            if (index === ind) {
              let obj = {}
              obj['disabled'] = true
              return gridRefCopy[index] = obj
            }
          })
        })
        let filterArrayForComment = array.filter((data, index) => {
          data['index'] = index
          return data.managerRecommendation === 'Approved'
        })
        let gridRef = this.uiSchema.elements[0].elements[0].elements[1].elements[0]
        let rowConfigs = arr;
        // let gridRefCopyNew = rowConfigs.rowConfigs
        filterArrayForComment.forEach((arr) => {
          let rowIndex = arr.index
          rowConfigs.forEach((data, ind) => {
            if (rowIndex === ind) {
              let obj = {}
              rowConfigs[rowIndex] = rowConfigs[rowIndex] || {};
              rowConfigs[rowIndex].cellConfig = [];
              rowConfigs[rowIndex].cellConfig[gridRef.colConfigs.length - 2] = {};
              // gridRef.colConfigs[gridRef.colConfigs.length-2].readonly= false
              rowConfigs[rowIndex].cellConfig[gridRef.colConfigs.length - 2].readonly = false;
              return gridRef[gridRef] = obj
            }
          })
        });
      } 
      else {
        rows.rowConfigs = arr;
        let gridRefCopy = rows.rowConfigs
        gridRefCopy.forEach((data, ind) => {
          let obj = {}
          obj['disabled'] = false
          return gridRefCopy[ind] = obj
        })
      }
    },
    showSearchResultsAfterUpdate(userData,gridRef) {
      let filteredOnName = '';
      this.uiData.rl_recoveries = userData
      this.uiData.rl_recoveries.manager_data = userData.manager_data
      this.uiData.rl_recoveries.tech_lead_data = userData.tech_lead_data

      let newVal = this.uiData.searchQuery
      if (this.isManager) {
        this.recordsAfterPagination = userData.manager_data
        this.uiData.rl_recoveries.manager_data = this.recordsAfterPagination
        filteredOnName = this.recordsAfterPagination.filter(r =>
          r.uEmail.toLowerCase().includes(newVal)
        );
      }
      else {
        this.recordsAfterPagination = userData.tech_lead_data
        this.uiData.rl_recoveries.tech_lead_data = this.recordsAfterPagination
        filteredOnName = this.recordsAfterPagination.filter(r =>
          r.uEmail.toLowerCase().includes(newVal)
        );
      }
      let startIndex = (this.paginatePageNumber - 1) * this.gridConfig.pageSize
      let endIndex = startIndex + this.gridConfig.pageSize;
      this.recordsAfterPagination = filteredOnName
      this.uiData.filteredData = filteredOnName
      this.gridConfig.totalItems = this.uiData.filteredData.length;
      this.uiData.filteredData = this.recordsAfterPagination.slice(startIndex, endIndex);
      this.uiData.filteredData = this.uiData.filteredData?.filter((_data, index) => {
        return this.filterByPagination(index);
      });
      this.rowsDisabled(gridRef, this.uiData.filteredData)
    },
    getManagerLeadList(data){
      this.uiData.managerList = data.manager_data
      this.uiData.techLeadList = data.tech_lead_data
        let loggedInManager = this.uiSchema.elements[0].elements[0].elements[0].elements[0]
        loggedInManager.customOptions.disabled = false
        this.uiData.managerList = this.uiData.managerList.sort().map(i => {
          return {
            text: i,
            value: i
          };
        })
        this.uiData.techLeadList = this.uiData.techLeadList.sort().map(i => {
          return {
            text: i,
            value: i
          };
        })
        // let listOfManager = this.uiSchema.elements[0].elements[0].elements[0].elements[1]
        // listOfManager.selectOptions = this.uiData.managerList
        this.showData = true;
    },
    getManagerAndLeadList(payload){
      let listOfManager = this.uiSchema.elements[0].elements[0].elements[0].elements[1]
      if(payload == 1 || payload == ""){
        listOfManager.selectOptions = this.uiData.managerList
      }else{
        listOfManager.selectOptions = this.uiData.techLeadList
      }
      this.addColumnsToGrid(payload)
    },
    onSelectLeadManagerFromAdmin(email){
      if(email){
        let listOfManager = this.uiSchema.elements[0].elements[0].elements[0].elements[1]
        this.payloadForUpdateAPI.employees = [];
        this.uiData.searchQuery = '';
        this.emailSelected = email
        let updateUserButton = this.uiSchema.elements[0].elements[0].elements[0].elements[3]
        updateUserButton.customOptions.disabled = true
        let emailExist = listOfManager.selectOptions.filter((list)=>{
          return list.value === email
        })
        if(emailExist.length>0){
          this.getEmployeesList(email)
        }
      }
    },
    addColumnsToGrid(payload){
      let gridConfigForPagination = this.uiSchema.elements[0].elements[0].elements[2].elements[0]
      gridConfigForPagination.hidden = true
      let gridRef = this.uiSchema.elements[0].elements[0].elements[1].elements[0]
      let colConfigLength = gridRef.colConfigs.length
      if(JSON.parse(payload) === 1){
        if (gridRef.colConfigs.length == 7) {
          gridRef.colConfigs.splice(colConfigLength - 2, 2)
          gridRef.colConfigs = gridRef.colConfigs.concat(this.colsConfigManager)
        }
      }else{
        if (gridRef.colConfigs.length == 7) {
          gridRef.colConfigs.splice(colConfigLength - 2, 2)
          gridRef.colConfigs = gridRef.colConfigs.concat(this.colsConfigLead)
        }
      }
    },
    onEnterComment(data){
      let rowValues = data.row
      this.gridRow = rowValues
      this.payloadForUpdateAPI.employees.filter((rec,index)=>{
         if(rec.accessType === rowValues.accessType && rec.uEmail===rowValues.uEmail&&rec.name===rowValues.name)
         {
          return this.payloadForUpdateAPI.employees[index].comment = data.$event.currentTarget.value
         }
      })
    },
    onDataPaste(e){
      if(Array.isArray(e)){
        let rowValues = this.gridRow
        let commentValue = e[0][0]
        this.payloadForUpdateAPI.employees.filter((rec,index)=>{
          if(rec.managerRecommendation === "Approved" && rec.accessType === rowValues.accessType && rec.uEmail===rowValues.uEmail&&rec.name===rowValues.name)
          {
            return this.payloadForUpdateAPI.employees[index].comment = commentValue
          }
        })
      }
    },
    handleError(err) {
      if(err.response && err.response.status === 400){
        this.showErrorAlert('Bad Request. Please contact dbrs_access_review@morningstar.com to report this issue')
      }else if(err.response && err.response.status === 401){
        this.showErrorAlert('You are not Authorized.')
      }else{
        this.showErrorAlert('Something went wrong. Please contact dbrs_access_review@morningstar.com to report this issue')
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.update-btn {
  float: right;
}

.pagination-grid {
  margin: auto;
  width: 30%;
  padding: 10px;
}
.pagination-grid .mds-pagination .mds-pagination__left .mds-pagination__select{
  width: 60px;
}
.mds-tooltip{
  max-width: 300px;
}
.bottom-text{
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
</style>