<template>
  <div>
  </div>
</template>

<script>
import { getManagersAndTechLead } from '../apis/ci-audit.js';
export default {
  name: 'Dashboard',
  components: {  },
  props: ['showLoaderToggle',"showErrorAlert"],
  data() {
    return {
      managerAdmin:'',
      techLeadAdmin:'',
      managerList:[],
      techLeadList:[]
    }
  },
  mounted() {
    this.getManagersAndTechLeadList();
  },
  methods: {
    // fethcing list of managers and tech leads
    getManagersAndTechLeadList(){
      // this.showLoaderToggle(true)
      return getManagersAndTechLead()
      .then((resp) => {
        this.managerList = resp.data.manager_data
        this.techLeadList = resp.data.tech_lead_data
        this.$emit("managerLeadList",resp.data)
      })
      .catch((err) => {
        console.log(err)
        this.handleError(err)
      })
      .finally(() => {
        // this.showLoaderToggle(false)
      });
    },
    handleError(err) {
      if(err.response && err.response.status === 400){
        this.showErrorAlert('Bad Request. Please contact dbrs_access_review@morningstar.com to report this issue.')
      }else if(err.response && err.response.status === 401){
        this.showErrorAlert('You are not Authorized.')
      }else{
        this.showErrorAlert('Something went wrong. Please contact dbrs_access_review@morningstar.com to report this issue')
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>